import { faUsers } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import getPodcastHasGuests from "utils/entity/getPodcastHasGuests";
import getPodcastUrl from "utils/entity/getPodcastUrl";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import faNoUsers from "styles/icons/faNoUsers";

const outerStyle = {
  // TODO: Share with ClaimedBadge?
  fontSize: "0.9em",
  lineHeight: 1,
  whiteSpace: "nowrap",
  display: "inline-flex",
  verticalAlign: "middle",
};

const baseStyles = {
  hasGuestsBadge: {},
  tooltip: {
    ...outerStyle,
  },
  noTooltip: {
    ...outerStyle,
  },
  linkContainer: {
    whiteSpace: "nowrap",
    marginLeft: "0.5rem",
    display: "inline-flex",
    alignItems: "center",
  },
  iconContainer: {
    fontSize: "1rem",
  },
};

const tooltippedStyles = {
  linkContainer: {
    marginLeft: 0,
  },
};

const tooltipStyles = {
  tooltip: {
    zIndex: 9999,
  },
};

const ICON_COLORS = {
  HAS_GUESTS: colours.hasGuestsTrue,
  NEVER_HAS_GUESTS: colours.hasGuestsFalse,
  UNLIKELY_HAS_GUESTS: colours.hasGuestsTrue,
};

const HasGuestsBadge = (props) => {
  const { entity, showTooltip, size } = props;

  const podcastHasGuests = getPodcastHasGuests(entity);

  const { tooltipText, icon, iconColor } = useMemo(() => {
    switch (podcastHasGuests) {
      case true:
        return {
          tooltipText: "Has guests",
          icon: faUsers,
          iconColor: colours.hasGuestsTrue,
        };
      case "never":
        return {
          tooltipText: "Never has guests",
          icon: faNoUsers,
          iconColor: colours.hasGuestsFalse,
        };
      case false:
      default:
        return {
          tooltipText: "Unlikely to have guests",
          icon: faNoUsers,
          iconColor: colours.hasGuestsFalse,
        };
    }
  }, [podcastHasGuests]);

  const iconColorStyles = useMemo(
    () => ({
      iconContainer: {
        color: iconColor,
      },
    }),
    [iconColor]
  );

  const iconSizeStyles = useMemo(
    () => ({
      iconContainer: {
        fontSize: `${Math.round(size * 100) / 100}px`,
      },
    }),
    [size]
  );

  const { styles } = useStyles(
    [
      baseStyles,
      showTooltip && tooltipText && tooltippedStyles,
      iconColor && iconColorStyles,
      size && iconSizeStyles,
    ],
    props
  );

  const handleUserEvents = useCallback((e) => e.stopPropagation(), []);
  const renderTooltipCallBack = useCallback(() => tooltipText, [tooltipText]);

  if (podcastHasGuests === undefined) {
    return null;
  }

  const badge = (
    <Link
      className={css(styles.linkContainer)}
      to={getPodcastUrl(entity, "/insights")}
      onMouseDown={handleUserEvents}
      onMouseUp={handleUserEvents}
      onClick={handleUserEvents}
    >
      <span className={css(styles.iconContainer)}>
        <FontAwesomeIcon icon={icon} />
      </span>
    </Link>
  );

  return showTooltip && tooltipText ? (
    <BasicTooltip renderTooltip={renderTooltipCallBack} styles={tooltipStyles}>
      {(tooltipProps) => (
        <span
          {...tooltipProps}
          className={css(styles.hasGuestsBadge, styles.tooltip)}
        >
          {badge}
        </span>
      )}
    </BasicTooltip>
  ) : (
    <span className={css(styles.hasGuestsBadge, styles.noTooltip)}>
      {badge}
    </span>
  );
};

HasGuestsBadge.propTypes = {
  entity: PropTypes.instanceOf(Map),
  showTooltip: PropTypes.bool,
  size: PropTypes.number,
};

HasGuestsBadge.defaultProps = {
  entity: null,
  showTooltip: true,
  size: undefined,
};

export default memo(HasGuestsBadge);
