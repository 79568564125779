import * as PropTypes from "prop-types";
import { memo, useCallback } from "react";

import EntityImage from "../Entities/EntityImage";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  podcastTitle: {
    ...gStyles.avalonBold,
    display: "block",
    marginTop: "0.5rem",
    textAlign: "center",
    fontSize: "1rem",
  },
  imagePlaceholder: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    aspectRatio: "1 / 1",
  },
};

const PodcastGridItem = (props) => {
  const {
    children,
    className,
    disableLink,
    entityLinkStyles,
    fullWidth,
    imageSize,
    imgProps,
    onClick,
    podcast,
    imageWidth,
    disablePopup,
  } = props;

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("tiny");
  const { css, styles } = useStyles(baseStyles, props);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(podcast);
    }
  }, [onClick, podcast]);

  const imageHeight = mobile ? "23.3rem" : imageWidth;

  return (
    <EntityImage
      disablePopup={disablePopup}
      className={className}
      styles={entityLinkStyles}
      entity={podcast}
      entity_type="podcast"
      onClick={handleClick}
      size={imageSize}
      fullWidth={fullWidth}
      disableLink={disableLink}
      imgProps={{
        loader: <div className={css(styles.imagePlaceholder)} />,
        ...imgProps,
      }}
      height={imageHeight}
    >
      {children}
    </EntityImage>
  );
};

PodcastGridItem.propTypes = {
  children: PropTypes.node,
  disablePopup: PropTypes.bool,
  showTitles: PropTypes.bool,
  showBadges: PropTypes.bool,
  showAuthority: PropTypes.bool,
  imageWidth: PropTypes.string,
  className: PropTypes.string,
  disableLink: PropTypes.bool,
  entityLinkStyles: PropTypes.object,
  fullWidth: PropTypes.bool,
  imageSize: PropTypes.number,
  imgProps: PropTypes.object,
  onClick: PropTypes.func,
  podcast: PropTypes.object.isRequired,
};

PodcastGridItem.defaultProps = {
  className: "",
  entityLinkStyles: undefined,
  onClick: undefined,
};

export default memo(PodcastGridItem);
