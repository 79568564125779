import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment } from "react";

import Dotdotdot from "components/Common/Dotdotdot";

import HeaderVerifiedBadge from "../../pages/PodcastView/HeaderVerifiedBadge";
import AuthorityBadge from "./AuthorityBadgeAsync";
import ClaimedBadge from "./ClaimedBadgeAsync";
import HasGuestsBadge from "./HasGuestsBadge";

import getPodcastAuthorityLevel from "utils/entity/getPodcastAuthorityLevel";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  podcastTitle: {
    ...gStyles.avalonBold,
    display: "block",
    marginTop: "0.5rem",
    textAlign: "center",
    fontSize: "1rem",
  },
  podcastTitleClamp: {
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",

    [ScreenSizes.mdAndAbove]: {
      "-webkit-line-clamp": "3",
    },
  },
};
const verifiedBadgeStyles = {
  verifiedBadge: {
    height: 17,
    width: "1rem",
    marginTop: "-0.2rem",
    marginRight: "unset",
    marginLeft: "0.5rem",
    [ScreenSizes.lgAndAbove]: {
      height: 17,
      marginRight: "unset",
    },
  },
};
const hasGuestsStyles = {
  hasGuestsBadge: {
    marginLeft: "0.5rem",
  },
};

const PodcastGridTitleAndBadges = (props) => {
  const { podcast, showTitles, showBadges, showAuthority, showIsVerified } =
    props;

  const { styles } = useStyles(baseStyles, props);

  const isClaimed = podcast.get("is_claimed");
  const badgeSize = 13;
  const badgeLabelSize = 11;
  const hasPower = showAuthority && getPodcastAuthorityLevel(podcast, 0);

  const { isWindowSizeOrLess } = useWindowSize();

  const mobile = isWindowSizeOrLess("medium");

  const claimedBadge = isClaimed && (
    <ClaimedBadge showLabel={false} size={badgeSize} showTooltip />
  );

  const authorityBadge = (
    <AuthorityBadge
      entity_type="podcast"
      entity={podcast}
      score={getPodcastAuthorityLevel(podcast, 0)}
      size={badgeSize}
      labelSize={badgeLabelSize}
      tooltipText={`Power Score: ${getPodcastAuthorityLevel(podcast, 2)}`}
      showTooltip
    />
  );
  const hasGuestBadge = (
    <HasGuestsBadge
      entity={podcast}
      key="hasGuestsBadge"
      size={badgeSize}
      styles={hasGuestsStyles}
    />
  );

  const verifiedBadge = showIsVerified && (
    <HeaderVerifiedBadge styles={verifiedBadgeStyles} podcast={podcast} />
  );

  const badges = (
    <Fragment>
      {claimedBadge}
      {verifiedBadge}
      {authorityBadge}
      {hasGuestBadge}
    </Fragment>
  );

  return (
    (hasPower || showTitles) &&
    showBadges &&
    Boolean(badges) && (
      <span className={css(styles.podcastTitle)}>
        {showTitles && (
          <Dotdotdot
            clamp={mobile ? 1 : 3}
            useNativeClamp
            tagname="span"
            className={css(styles.podcastTitleClamp)}
          >
            {podcast.get("title")} {!hasPower && showBadges && badges}
          </Dotdotdot>
        )}
        {(hasPower || !showTitles) && showBadges && badges}
      </span>
    )
  );
};

PodcastGridTitleAndBadges.propTypes = {
  podcast: PropTypes.object.isRequired,
  showTitles: PropTypes.bool,
  showBadges: PropTypes.bool,
  showAuthority: PropTypes.bool,
  showIsVerified: PropTypes.bool,
};

PodcastGridTitleAndBadges.defaultProps = {
  showTitles: false,
  showBadges: true,
  showAuthority: true,
  showIsVerified: null,
};

export default memo(PodcastGridTitleAndBadges);
